//
//
//
//

import { useContext, watch } from "@nuxtjs/composition-api";
import { useStoryblok } from "@storyblok/nuxt-2";
const __sfc_main = {
  layout(context) {
    return /^\/case-studies\//.test(context.route.path) ? "case-study" : null;
  },

  head: {
    link: [{
      hid: "shortcut-icon",
      rel: "shortcut icon",
      type: "image/x-icon",
      href: "/favicon.png"
    }]
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const {
    error: nuxtError,
    route
  } = useContext();
  const slug = route.value.params.pathMatch;
  const {
    fetchState,
    story
  } = useStoryblok(slug || "home", {
    version: process.env.SITE_ENV === "development" ? "draft" : null
  });
  watch(fetchState, ({
    error
  }) => {
    if (error) nuxtError(error);
  });
  return {
    story
  };
};

export default __sfc_main;
